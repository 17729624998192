<template>
  <div>
    <div class="modal" id="unit-earner-modal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <form @submit.prevent="generateCAV">
            <div class="modal-body">
              <p class="mb-2">
                {{ Date() | standardDate }}
              </p>
              <strong>CAV (CHED-CARAGA) NO. {{ app.control_no }}</strong>
              <p>Series of {{ new Date().getFullYear() }}</p>

              <table class="table table-bordered table-sm mt-1 text-uppercase">
                <tbody>
                  <tr>
                    <td width="35%">App No</td>
                    <th>
                      {{ app.id }}
                    </th>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <th>
                      {{ app.firstname }} {{ app.middlename }}
                      {{ app.lastname }}
                    </th>
                  </tr>
                  <tr>
                    <td>SECPA No</td>
                    <th>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.secpa_no"
                        :disabled="app.purpose == 'Local' ? true : false"
                      />
                    </th>
                  </tr>

                  <tr>
                    <td>OR No</td>
                    <th>
                      {{ app.receipt_no }} ({{ app.receipt_date | shortDate }})
                    </th>
                  </tr>

                  <tr>
                    <td>Degree</td>
                    <td>
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="so.progName"
                        :disabled="!heiPrograms.length"
                        required
                      >
                        <option value="" selected disabled hidden>
                          Choose
                        </option>
                        <option
                          v-for="program in heiPrograms"
                          :key="program.id"
                          :value="program.program_name"
                        >
                          {{ program.program_name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td>Date Started</td>
                    <td>
                      <div class="input-group">
                        <select
                          name=""
                          id=""
                          class="form-control"
                          v-model="so.semEnrolled"
                          required
                        >
                          <option value="" selected disabled hidden>
                            Choose semester
                          </option>
                          <option value="1ST SEMESTER">1ST SEMESTER</option>
                          <option value="2ND SEMESTER">2ND SEMESTER</option>
                          <option value="3RD SEMESTER">3RD SEMESTER</option>
                          <option value="SUMMER">SUMMER</option>
                          <option value="1ST TRIMESTER">1ST TRIMESTER</option>
                          <option value="2ND TRIMESTER">2ND TRIMESTER</option>
                          <option value="3RD TRIMESTER">3RD TRIMESTER</option>
                        </select>

                        <select
                          name=""
                          id=""
                          class="form-control"
                          v-model="so.acadYear"
                          required
                        >
                          <option value="" selected disabled hidden>
                            Choose academic year
                          </option>
                          <option value="2023-2024">2023-2024</option>
                          <option value="2022-2023">2022-2023</option>
                          <option value="2021-2022">2021-2022</option>
                          <option value="2020-2021">2020-2021</option>
                          <option value="2019-2020">2019-2020</option>
                          <option value="2018-2019">2018-2019</option>
                          <option value="2017-2018">2017-2018</option>
                          <option value="2016-2017">2016-2017</option>
                          <option value="2015-2016">2015-2016</option>
                          <option value="2014-2015">2014-2015</option>
                          <option value="2013-2014">2013-2014</option>
                          <option value="2012-2013">2012-2013</option>
                          <option value="2011-2012">2011-2012</option>
                          <option value="2010-2011">2010-2011</option>
                          <option value="2009-2010">2009-2010</option>
                          <option value="2008-2009">2008-2009</option>
                          <option value="2007-2008">2007-2008</option>
                          <option value="2006-2007">2006-2007</option>
                          <option value="2005-2006">2005-2006</option>
                          <option value="2004-2005">2004-2005</option>
                          <option value="2003-2004">2003-2004</option>
                          <option value="2002-2003">2002-2003</option>
                          <option value="2001-2002">2001-2002</option>
                          <option value="2000-2001">2000-2001</option>
                          <option value="1999-2000">1999-2000</option>
                          <option value="1998-1999">1998-1999</option>
                          <option value="1997-1998">1997-1998</option>
                          <option value="1996-1997">1996-1997</option>
                          <option value="1995-1996">1995-1996</option>
                          <option value="1994-1995">1994-1995</option>
                          <option value="1993-1994">1993-1994</option>
                          <option value="1992-1993">1992-1993</option>
                          <option value="1991-1992">1991-1992</option>
                          <option value="1990-1991">1990-1991</option>
                          <option value="1989-1990">1989-1990</option>
                          <option value="1988-1989">1988-1989</option>
                          <option value="1987-1988">1987-1988</option>
                          <option value="1986-1987">1986-1987</option>
                          <option value="1985-1986">1985-1986</option>
                          <option value="1984-1985">1984-1985</option>
                          <option value="1983-1984">1983-1984</option>
                          <option value="1982-1983">1982-1983</option>
                          <option value="1981-1982">1981-1982</option>
                          <option value="1980-1981">1980-1981</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Date Ended</td>
                    <td>
                      <div class="input-group">
                        <select
                          name=""
                          id=""
                          class="form-control"
                          v-model="so.semStarted"
                          required
                        >
                          <option value="" selected disabled hidden>
                            Choose semester
                          </option>
                          <option value="1ST SEMESTER">1ST SEMESTER</option>
                          <option value="2ND SEMESTER">2ND SEMESTER</option>
                          <option value="3RD SEMESTER">3RD SEMESTER</option>
                          <option value="SUMMER">SUMMER</option>
                          <option value="1ST TRIMESTER">1ST TRIMESTER</option>
                          <option value="2ND TRIMESTER">2ND TRIMESTER</option>
                          <option value="3RD TRIMESTER">3RD TRIMESTER</option>
                        </select>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          v-model="so.semYear"
                          required
                        >
                          <option value="" selected disabled hidden>
                            Choose academic year
                          </option>
                          <option value="2023-2024">2023-2024</option>
                          <option value="2022-2023">2022-2023</option>
                          <option value="2021-2022">2021-2022</option>
                          <option value="2020-2021">2020-2021</option>
                          <option value="2019-2020">2019-2020</option>
                          <option value="2018-2019">2018-2019</option>
                          <option value="2017-2018">2017-2018</option>
                          <option value="2016-2017">2016-2017</option>
                          <option value="2015-2016">2015-2016</option>
                          <option value="2014-2015">2014-2015</option>
                          <option value="2013-2014">2013-2014</option>
                          <option value="2012-2013">2012-2013</option>
                          <option value="2011-2012">2011-2012</option>
                          <option value="2010-2011">2010-2011</option>
                          <option value="2009-2010">2009-2010</option>
                          <option value="2008-2009">2008-2009</option>
                          <option value="2007-2008">2007-2008</option>
                          <option value="2006-2007">2006-2007</option>
                          <option value="2005-2006">2005-2006</option>
                          <option value="2004-2005">2004-2005</option>
                          <option value="2003-2004">2003-2004</option>
                          <option value="2002-2003">2002-2003</option>
                          <option value="2001-2002">2001-2002</option>
                          <option value="2000-2001">2000-2001</option>
                          <option value="1999-2000">1999-2000</option>
                          <option value="1998-1999">1998-1999</option>
                          <option value="1997-1998">1997-1998</option>
                          <option value="1996-1997">1996-1997</option>
                          <option value="1995-1996">1995-1996</option>
                          <option value="1994-1995">1994-1995</option>
                          <option value="1993-1994">1993-1994</option>
                          <option value="1992-1993">1992-1993</option>
                          <option value="1991-1992">1991-1992</option>
                          <option value="1990-1991">1990-1991</option>
                          <option value="1989-1990">1989-1990</option>
                          <option value="1988-1989">1988-1989</option>
                          <option value="1987-1988">1987-1988</option>
                          <option value="1986-1987">1986-1987</option>
                          <option value="1985-1986">1985-1986</option>
                          <option value="1984-1985">1984-1985</option>
                          <option value="1983-1984">1983-1984</option>
                          <option value="1982-1983">1982-1983</option>
                          <option value="1981-1982">1981-1982</option>
                          <option value="1980-1981">1980-1981</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Units Earned</td>
                    <td>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control text-uppercase"
                          placeholder="Ex. THIRTY-THREE (33) UNITS"
                          required
                          v-model="form.units_earned"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Mode of Study</td>
                    <td>
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="form.study_mode"
                        required
                      >
                        <option value="CONVENTIONAL" selected>
                          CONVENTIONAL
                        </option>

                        <option value="CONVENTIONAL AND BLENDED">
                          CONVENTIONAL AND BLENDED
                        </option>

                        <option value="CONVENTIONAL AND ONLINE">
                          CONVENTIONAL AND ONLINE
                        </option>
                        <option value="CONVENTIONAL AND OFFLINE">
                          CONVENTIONAL AND OFFLINE
                        </option>
                        <option value="ONLINE AND BLENDED">
                          ONLINE AND BLENDED
                        </option>
                        <option value="OFFLINE AND BLENDED">
                          OFFLINE AND BLENDED
                        </option>
                        <option value="BLENDED">BLENDED</option>
                        <option value="ONLINE">ONLINE</option>
                        <option value="OFFLINE">OFFLINE</option>
                        <option value="NON-CONVENTIONAL">
                          NON-CONVENTIONAL
                        </option>
                        <option value="ETEEAP">ETEEAP</option>
                        <option value="ETEEAP AND ONLINE">
                          ETEEAP AND ONLINE
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Institution</td>
                    <th>
                      {{ app.hei_name }}
                    </th>
                  </tr>

                  <tr>
                    <td>Reviewed by</td>
                    <td>
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="form.reviewed_by"
                        required
                      >
                        <option value="Francis D. Ilagan">
                          FRANCIS D. ILAGAN
                        </option>
                        <option value="Misael S. Cabang">
                          MISAEL S. CABANG
                        </option>
                        <option value="Revelyn P. Brina">
                          REVELYN P. BRINA
                        </option>
                        <option value="Angel A. Asis">ANGEL A. ASIS</option>

                        <option value="Geselle M. Francisco">
                          GESELLE M. FRANCISCO
                        </option>
                        <option value="Haemaccel I. Palima">
                          HAEMACCEL I. PALIMA
                        </option>
                        <option value="Ma. Mercedes P. Rebita">
                          MA. MERCEDES P. REBITA
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Signed by</td>
                    <td>
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="form.signed_by"
                        required
                      >
                        <option
                          value="GEORGE M. COLORADO, PhD, CESO III : Director IV"
                          selected
                        >
                          GEORGE M. COLORADO, PhD, CESO III : Director IV
                        </option>
                        <option
                          value="ALMA SY-PATRON, PHD, RSW : OIC, Office of the Regional Director"
                        >
                          ALMA SY-PATRON, PHD, RSW : OIC, Office of the Regional
                          Director
                        </option>
                        <option
                          value="JULIA FELISA C. MARTINEZ, PhD,LPT : Chief Education Program Specialist"
                        >
                          JULIA FELISA C. MARTINEZ, PhD,LPT : Chief Education
                          Program Specialist
                        </option>
                        <option
                          value="NOEME ROSE D. MANI, MM, CPA : SEPS/OIC, Chief Administrative Officer"
                        >
                          NOEME ROSE D. MANI, MM, CPA : SEPS/OIC, Chief
                          Administrative Officer
                        </option>
                        <option
                          value="REVELYN P. BRINA, MAED : Administrative Officer III"
                        >
                          REVELYN P. BRINA, MAED : Administrative Officer III
                        </option>

                        <option
                          value="GESELLE M. FRANCISCO, CPA : Accountant II"
                        >
                          GESELLE M. FRANCISCO, CPA : Accountant II
                        </option>
                        <option
                          value="AMY C. SAAVEDRA, MSCA : Education Supervisor II"
                        >
                          AMY C. SAAVEDRA, MSCA : Education Supervisor II
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="mb-0">Date & Time: {{ Date() | dateTime }}</p>
              <p>
                Processed by: {{ $store.state.user.first_name }}
                {{ $store.state.user.last_name }}
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                id="generate-ue-btn"
                type="submit"
                class="btn btn-primary"
              >
                Generate CAV
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heis: [],
      acadYears: [],
      heiPrograms: [],
      app: {},
      so: new Form({
        progName: "",
        schoolCode: "",
        semEnrolled: "",
        acadYear: "",
        semStarted: "",
        semYear: "",
      }),
      form: new Form({
        units_earned: "",
        secpa_no: "",
        study_mode: "CONVENTIONAL",
        reviewed_by: "Misael S. Cabang",
        signed_by:
          "GESELLE M. FRANCISCO, CPA : OIC, Chief Administrative Officer",
      }),
    };
  },
  methods: {
    openModal(app) {
      this.form.reset();
      this.so.reset();
      this.getHeiPrograms(app.hei_code);

      $("#unit-earner-modal").modal("show");
      this.app = app;
      this.so.schoolCode = app.school_graduated;
      if (this.app.purpose == "Local") {
        this.form.secpa_no = `${this.app.control_no.substring(
          2
        )}-${new Date().getFullYear()}`;
      }
    },
    getHeiPrograms(hei_code) {
      axios
        .get("api/hei-programs/" + hei_code)
        .then((response) => {
          this.heiPrograms = response.data;
        })
        .catch((error) => {
          toastr.danger("Something went wrong", "Request failed");
        });
    },

    generateCAV() {
      let btn = document.getElementById("generate-ue-btn");
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> Generating...`;
      btn.disabled = true;

      axios
        .post("api/cavs/unit-earner", {
          app: this.app,
          so: this.so,
          form: this.form,
        })
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = "Generate CAV";
          this.$emit("getApps");
          this.$emit("print", res.data);
          $("#unit-earner-modal").modal("hide");
          toastr.success(
            `Generated successfully.            
             Name: ${res.data.lastname} 
            `,
            `(CAV ID: ${res.data.id})`
          );
        })
        .catch((err) => {
          btn.disabled = false;
          btn.innerHTML = "Generate CAV";
          toastr.error("Something went wrong", "Error");
        });
    },
  },
  mounted() {},
};
</script>
